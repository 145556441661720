
/* Overwrite bootstrap progress bar  */

.progress-bar {
    display: flex;
    /* min-width: 400px; */
    /* width: 400px; */
    /* flex-direction: column; */
    /* justify-content: center; */
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: rgb(250, 163, 58);
    transition: width .6s ease;
}

.progress {
    width: 100%;
    height: 100%;
}