

.AssessmentFrame {
    max-width: 640px;
    max-height: 580px;
    margin: 0 auto;
}

.HomeLink {
    margin-top: 20px;
    margin-bottom: 20px;
    display: inherit;
}

.CardGrid {
    margin-top: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
}
  
.Card {
    height: '100%';
    width: '100%';
    display: inherit;
    flex-direction: 'column';
}
  
.CardMedia {
    
    width: 280px;
    height: 240px;
    object-fit: 'cover';
}
  
.CardContent {
    flex-grow: 1;
    height: 80px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.AssessmentTitle {
    padding-top: 20px;
    padding-bottom: 20px;
    display:flex;
    justify-content: center;
}


.ScoreCard {
    height: 700px;
    width: 900px;
    justify-content: center;
}

.ScoreCardContent {
    flex-grow: 1;
    height: 80px;
}

.ScoreCardHeader {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
}

.ScoreCardScoreLabel {
    justify-content: center;
    display: inherit;
    flex: 1;
}

.ScoreCardScoreValue {
    justify-content: center;
    display: inherit;
    flex: 1;
}

.ScoreValueContainer {
    display: flex;
    flex-direction: row;
}